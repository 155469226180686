import * as React from "react";
import { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import * as Styled from "./mapWithMarkersStyles";
import LocationPinMarker from "../../images/blue_pin.png";
import GrayPin from "../../images/gray_pin.png";
import GreenPin from "../../images/green_pin.png";
import GoogleMapReact from "google-map-react";
import { Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "./style.css";

let markersArray = [];
/*global google */

const GeneralLocationArray = [
  { country: "Barbados", latitude: 13.193887, longitude: -59.543198 },
  { country: "Puerto Rico", latitude: 18.200178, longitude: -66.664513 },
];

const Location = () => {
  const locationDataQuery = useStaticQuery(graphql`
    query locationDataMyQuery {
      allContentfulSolLocations(filter: { node_locale: { eq: "en-US" } }) {
        nodes {
          energy {
            title
            url
          }
          segment
          sitename
          status
          commissioningOrAcquisition
          grossCapacityKWp
          country
          latitude
          longitude
          co2OffsetEquivalency
          note {
            raw
          }
          locationImage {
            raw
          }
          locationPdf {
            raw
          }
        }
      }
    }
  `);

  const locationsData = locationDataQuery?.allContentfulSolLocations?.nodes;
  const lat = isNaN(parseFloat(locationsData[0]?.latitude))
    ? 25.058258
    : parseFloat(locationsData[0]?.latitude);
  const lng = isNaN(parseFloat(locationsData[0]?.longitude))
    ? -77.316869
    : parseFloat(locationsData[0]?.longitude);
  const zoom = 4;
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();
  let InfoWindowObject = null;

  const closeOtherInfo = () => {
    if (InfoWindowObject) {
      /* detach the info-window from the marker ... undocumented in the API docs */
      InfoWindowObject?.set("marker", null);
      /* and close it */
      InfoWindowObject?.close();
      /* blank the Object */
      InfoWindowObject = null;
    }
  };

  const getMarkerIcon = (type) => {
    let markerIcon = null;
    if (type === "Operational") {
      markerIcon = GreenPin;
    } else {
      markerIcon = LocationPinMarker;
    }

    return markerIcon;
  };

  useEffect(() => {
    //delete all markers
    if (markersArray?.length >= 1) {
      markersArray?.forEach((marker) => {
        marker?.setMap(null);
      });
      markersArray = [];
    }

    let addDistance = [];
    if (maps && map && markersArray?.length < 1) {
      locationsData?.forEach((location, index) => {
        if (location?.latitude && location?.latitude) {
          let marker;
          if (
            location?.latitude === "GENERAL" &&
            location?.longitude === "GENERAL"
          ) {
            const tempData = GeneralLocationArray?.find(
              (x) => x["country"] === location?.country
            );

            addDistance[tempData?.country] = addDistance[tempData?.country]
              ? addDistance[tempData?.country] + 0.005
              : 0.001;

            marker = new maps.Marker({
              position: new maps.LatLng(
                parseFloat(tempData?.latitude) ?? 0,
                parseFloat(tempData?.longitude) +
                  addDistance[tempData?.country] ?? 0
              ),
              icon: {
                url: getMarkerIcon(location?.status), // url
                scaledSize: new google.maps.Size(43, 40), // size
              },
              map: map,
            });
          } else {
            marker = new maps.Marker({
              position: new maps.LatLng(
                location?.latitude ?? 0,
                location?.longitude ?? 0
              ),
              icon: {
                url: getMarkerIcon(location?.status), // url
                scaledSize: new google.maps.Size(43, 40), // size
              },
              map: map,
            });
          }

          markersArray?.push(marker);
          const infoWindow = new maps.InfoWindow({
            content: `
                    <div class="infoWindow">
                        <h2>${location?.sitename} </h2>
                        <h6>Type : <span class='span'>Solar</span</h6>
                        <h6 class=''>Location : <span class='span'>${
                          location?.country
                        }</span</h6>
                        <h6>Gross Capacity (kWp) : <span class='span'>${
                          location?.grossCapacityKWp
                        }</span</h6>
                        <h6>CO2 Offset Equivalency (metric tons)* : <span class='span'>${
                          location?.co2OffsetEquivalency
                            ? location?.co2OffsetEquivalency
                            : "76"
                        }</span</h6>
                        <br/>
                        <br/>
                        <span class='right'>*estimated</span>
                        </div>
                    </div>`,
            ariaLabel: "Marker",
          });

          marker?.addListener("click", () => {
            closeOtherInfo();
            infoWindow?.open({
              anchor: marker,
              map,
            });
            map.panTo(marker?.getPosition());
            InfoWindowObject = infoWindow;
          });
        }
      });
    }
  }, [locationsData, map, maps]);

  const handleApiLoaded = (map, maps) => {
    const styles = [
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [{ color: "#98d0ff" }],
      },
    ];

    // eslint-disable-next-line no-undef
    const styledMap = new google.maps.StyledMapType(styles, {
      name: "Styled Map",
    });
    map.mapTypes.set("map_style", styledMap);
    map.setMapTypeId("map_style");
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Projects</title>
        <meta name="description" content></meta>
      </Helmet>
      <Styled.LocationContainer id="locationMap">
        <Row className="g-0 d-block">
          <div>
            <Styled.GoogleMapContainer>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.GATSBY_GOOGLE_MAP_APIKEY,
                  libraries: ["places", "geometry"],
                }}
                center={{ lat, lng }}
                zoom={zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                  setMap(map);
                  setMaps(maps);
                  handleApiLoaded(map, maps);
                }}
              />
            </Styled.GoogleMapContainer>
          </div>
        </Row>
      </Styled.LocationContainer>
    </React.Fragment>
  );
};

export default Location;
