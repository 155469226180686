import styled from "styled-components";

export const MainDiv = styled.div`
  margin-top: 5%;
  margin-bottom: 5%;
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const GoogleMapContainer = styled.div`
  height: 700px;
  display: flow-root;
  width: 90%;
  margin: 0 auto;
`;

export const LocationContainer = styled.div`
  width: 100% !important;
  padding: 0 !important;
  margin-top: 80px;
`;
